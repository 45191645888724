import { Injectable, computed, inject, signal } from '@angular/core';
import { ActiveFilterService } from '@ft/lib/active-filter-lib';
import { LayoutInfoService } from '@ft/lib/screen-lib';

const CompetitionsOpenText = `Show Completed Competitions`;
const CompetitionsOpenTextShort = `Completed Comps`;
const EntriesOpenText = `Only Entries Open`;
const EntriesOpenTextShort = `Only Open`;

export const CompetitionOpenFN = '_competitionOpen';
export const EntryOpenFN = '_entryOpen';

@Injectable({
	providedIn: 'root',
})
export class CompetitionsFilterService {
	private layout = inject(LayoutInfoService);
	afs = inject(ActiveFilterService);

	openCompetition = signal<boolean>(false);
	openEntry = signal<boolean>(false);

	openCompetitionsText = computed(() => {
		return this.layout.isLT_medium() ? CompetitionsOpenTextShort : CompetitionsOpenText;
	});

	openEntriesText = computed(() => {
		return this.layout.isLT_medium() ? EntriesOpenTextShort : EntriesOpenText;
	});

	constructor() {}

	onChangeOpenCompetition(fieldname: string, value: boolean) {
		this.afs.updateBooleanFilter(fieldname, value, 'notEmpty', 'yes');
	}

	onChangeOpenEntry(fieldname: string, value: boolean) {
		this.afs.updateBooleanFilter(fieldname, value, 'yes', '');
	}
}
